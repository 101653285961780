
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "date-range-pre": function (a) {
        var dates = a.indexOf("—") ? a.split('—') : a.split(' ');
        var dateA = dates[0].split('-');

        var year = $.trim(dateA[2]);
        var month = $.trim(dateA[0]);
        var day = $.trim(dateA[1]);

        if (month.length == 1)
            month = "0" + month;
        if (day.length == 1)
            day = "0" + day;

        return (year + month + day) * 1;
    },

    "date-range-asc": function (a, b) {
        var result = ((a < b) ? -1 : ((a > b) ? 1 : 0));
        return result;
    },

    "date-range-desc": function (a, b) {
        var result = ((a < b) ? 1 : ((a > b) ? -1 : 0));
        return result;
    }
});